<template>
  <v-parallax
    dark
    :src="imgSrc"
    height="300"
  >
    <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1']" class="mb-4">
            {{ title }}
          </h1>
        </v-col>
      </v-row>
    </v-overlay>
  </v-parallax>
</template>
<script>
export default {
  name: 'TopParallax',
  // eslint-disable-next-line vue/require-default-prop
  props: { title: String, imgSrc: String }
}
</script>
